import { CreateCustomerRequest } from '../models/api/createCustomerRequest';
import { UpdateSessionRequest } from '../models/api/updateSessionRequest';
import { UserSession } from '../models/api/userSession';

/**
 * Api client class.
 */
export class ApiClient {
  private static _baseUrl = process.env.GATSBY_API_URL;

  /**
   * Create a user on the server.
   * @param request Create customer request.
   * @returns A promise which resolves to a url to navigate to
   * when the user creation is complete.
   */
  public static async createUser(
    request: CreateCustomerRequest
  ): Promise<{ url: string }> {
    const response = await ApiClient.makeRequest(`${ApiClient._baseUrl}`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response.json();
  }

  /**
   * Get a user session by id.
   * @param userId User id to retrieve.
   * @param sessionId Id of the session to retrieve.
   * @returns Matching user session, if any. Otherwise, throws.
   */
  public static async getSession(
    userId: string,
    sessionId: string
  ): Promise<UserSession> {
    const response = await ApiClient.makeRequest(
      `${ApiClient._baseUrl}/${userId}/session/${sessionId}`
    );

    return await response.json();
  }

  /**
   * Update a user session.
   * @param userId Id of the user that owns the session to update.
   * @param sessionId Id of the session to update.
   * @param request Update request.
   */
  public static async updateSession(
    userId: string,
    sessionId: string,
    request: UpdateSessionRequest
  ): Promise<void> {
    await ApiClient.makeRequest(
      `${ApiClient._baseUrl}/${userId}/session/${sessionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  private static async makeRequest(req: RequestInfo, init?: RequestInit) {
    const response = await fetch(req, init);

    if (response.status > 300 || response.status < 200) {
      throw new Error('Error returned when making fetch request! ');
    }

    return response;
  }
}
